import USDT from "images/tokens/USDT.svg";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { getAccountUser } from "selectors/loginSelectors";

import { localStorageUtils } from "utils/browserUtils";

import styles from "./styles.module.scss";

const DetaislUrl =
  "https://tether.io/news/tether-announces-strategic-transition-to-prioritize-community-driven-blockchain-support/#:~:text=Tether%20will%20stop%20minting%20USD,and%20announced%20around%20that%20time.";

const cryptoBlocks = [
  {
    titles: [],
    descriptions: [
      "After the discount, you will still be able to use your USD₮ on EOS till June 24, 2025.",
    ],
  },
];

const WalletNotification = () => {
  const alreadyShown = localStorageUtils.getItem("walletNotificationShown") === "2";

  const user = useSelector(getAccountUser);
  const history = useHistory();

  const isNeedShow = useCallback(
    () => !alreadyShown && user && user.provider === "scatter",
    [alreadyShown, user]
  );

  const [isShown, setIsShown] = useState<boolean>(isNeedShow());

  useEffect(() => {
    if (isNeedShow()) {
      setIsShown(true);
    }
  }, [user?.provider]);

  const onClose = () => {
    localStorageUtils.setItem("walletNotificationShown", 2);
    setIsShown(false);
  };

  if (!isShown) return null;

  return (
    <Modal centered isOpen autoFocus={false} className={styles.wrapper} toggle={onClose}>
      <ModalHeader toggle={onClose}>Important notice</ModalHeader>
      <ModalBody style={{ paddingBottom: 16, borderRadius: "8px" }}>
        <div className={styles.body}>
          <p className={styles.subtitle}>
            <>
              As we've mentioned earlier, we are going to discount{" "}
              <img className={styles.cryptoImage} src={USDT} alt={USDT + "Icon"} /> USD₮
              on EOS starting December 16. The reason for that is that on June 24, 2024
              Tether has announced they'll stop minting{" "}
              <img className={styles.cryptoImage} src={USDT} alt={USDT + "Icon"} /> USD₮
              on EOS.{" "}
              <a
                href={DetaislUrl}
                className={styles.link}
                target="_blank"
                rel="noreferrer"
              >
                More details here
              </a>
              .
            </>
          </p>
          {cryptoBlocks.map(({ titles, descriptions }, index) => (
            <div className={styles.block} key={index}>
              <div className={styles.block_headers}>
                {titles.map(({ icon, name }, index) => (
                  <div className={styles.block_headers_header} key={index}>
                    <img className={styles.cryptoImage} src={icon} alt={name + "Icon"} />
                    <p className={styles.cryptoName}>{name}</p>
                  </div>
                ))}
              </div>
              {descriptions.map((text, index) => (
                <p key={index} className={styles.block_description}>
                  {text}
                </p>
              ))}
            </div>
          ))}
          <button
            className={styles.button}
            onClick={() => history.replace({ hash: "#wallet" })}
          >
            Wallet
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default WalletNotification;
