const casino = (state) => state.casino;
const gameList = (state) => casino(state).gameList;
const gameLobby = (state) => casino(state).gameLobby;
const game = (state) => casino(state).game;
const history = (state) => casino(state).history;

export const getGameList = (state) => gameList(state).data;
export const getGameListLoading = (state) => gameList(state).loading;
export const getGameListError = (state) => gameList(state).error;
export const getGameListPage = (state) => gameList(state).page;
export const getGameListProviders = (state) => gameList(state).providers;
export const getGameListSelectedProvider = (state) => gameList(state).selectedProvider;
export const getGameListCategories = (state) => gameList(state).categories;
export const getGameListLobbyCategories = (state) => gameList(state).lobbyCategories;
export const getGameListTotalCount = (state) => gameList(state).totalCount;
export const getGameListFavouriteCount = (state) => gameList(state).favouriteCount;
export const getGameListRecentCount = (state) => gameList(state).recentCount;
export const getGameListAllCount = (state) => gameList(state).allCount;
export const getGameListAllCountMobile = (state) => gameList(state).allCountMobile;
export const getGameListParams = (state) => {
  const { category, page, perPage, sortBy, providers, search, type, activePages } =
    gameList(state).params;

  return {
    category,
    page,
    perPage,
    sortBy,
    providers,
    search,
    type,
    activePages,
  };
};
export const getIsReloadRequired = (state) => gameList(state).isReloadRequired;
export const getGameLobby = (state) => gameLobby(state).data;
export const getGame = (state) => game(state).data;
export const getGameSessionId = (state) => game(state).sessionId;
export const getGameId = (state) => game(state)?.gameId;
export const getGameLoading = (state) => game(state).loading;
export const getGameError = (state) => game(state).error;
export const getGameRelated = (state) => game(state).related;
export const getGameDepositShown = (state) => game(state).depositShown;
export const getGameRefillShown = (state) => game(state).refillShown;
export const getGameRefillError = (state) => game(state).refillError;
export const getGameRefillProgress = (state) => game(state).refillProgress;
export const getGameEosId = (state) => game(state).eosId;
export const getGameStarting = (state) => game(state).gameLoading;
export const getGameStartError = (state) => game(state).gameError;
export const getGameUrl = (state) => game(state).gameUrl;
export const getGameMenuEnabled = (state) => game(state).menuEnabled;
export const getGameIsRealPlay = (state) => game(state).realPlay;
export const getCasinoHistory = (state) => history(state).data;
export const getCasinoHistoryLoading = (state) => history(state).loading;
export const getCasinoHistoryError = (state) => history(state).error;
export const getGameRate = (state) => game(state).rate;
export const getGameCurrency = (state) => game(state).currency;
export const getGameUserCurrency = (state) => game(state).userCurrency;
