import {
  CASINO_GAMELIST_CATEGORY_SET,
  CASINO_GAMELIST_CHANGE_ACTIVE_PAGES,
  CASINO_GAMELIST_CHANGE_PAGE,
  CASINO_GAMELIST_IS_RELOAD_REQUIRED,
  CASINO_GAMELIST_LOADING,
  CASINO_GAMELIST_LOAD_ERROR,
  CASINO_GAMELIST_LOBBY_CATEGORIES_SET,
  CASINO_GAMELIST_PAGE_SET,
  CASINO_GAMELIST_PARAMS_INIT,
  CASINO_GAMELIST_PROVIDERS_SET,
  CASINO_GAMELIST_SEARCH_SET,
  CASINO_GAMELIST_SORT_BY_SET, 
  CASINO_GAMELIST_PROVIDER_SET,
} from "../../actions/casino";
import { casinoPaginationChangeType } from "../../constants/casino";

const initialState = {
  loading: false,
  error: null,
  data: null,
  params: {
    loading: true,
    category: null,
    page: 1,
    perPage: 12,
    sortBy: "name",
    search: "",
    providers: [],
    type: null,
    activePages: [],
  },
  categories: [],
  lobbyCategories: [],
  providers: [],
  totalCount: 0,
  favouriteCount: 0,
  recentCount: 0,
  allCount: 0,
  allCountMobile: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CASINO_GAMELIST_PAGE_SET: {
      return {
        ...state,
        params: {
          ...state.params,
          page: Math.max(1, action.payload.page),
          type: action.payload.type,
        },
      };
    }
    case CASINO_GAMELIST_PARAMS_INIT: {
      return {
        ...state,
        loading: false,
        error: null,
        // data: null,
        params: {
          loading: false,
          ...state.params,
          ...action.payload,
        },
      };
    }
    case CASINO_GAMELIST_CATEGORY_SET: {
      return {
        ...state,
        params: {
          ...state.params,
          page: 1,
          category: action.payload === "lobby" ? null : action.payload,
          providers: [],
        },
      };
    }
    case CASINO_GAMELIST_LOBBY_CATEGORIES_SET: {
      const {
        items = null,
        meta: {
          allCount = 0,
          allMobile = 0,
          categories = {},
          totalCount = 0,
          currentProviders = [],
          favouriteCount = 0,
          recentCount = 0,
        } = {},
      } = action.payload;
      return {
        ...state,
        lobbyCategories: items,
        loading: false,
        error: null,
        totalCount,
        favouriteCount,
        recentCount,
        categories,
        providers: currentProviders,
        allCount,
        allCountMobile: allMobile,
      };
    }
    case CASINO_GAMELIST_SEARCH_SET: {
      return {
        ...state,
        params: {
          ...state.params,
          page: 1,
          search: action.payload,
        },
      };
    }
    case CASINO_GAMELIST_SORT_BY_SET: {
      return {
        ...state,
        params: {
          ...state.params,
          page: 1,
          sortBy: action.payload,
        },
      };
    }
    case CASINO_GAMELIST_PROVIDERS_SET: {
      return {
        ...state,
        params: {
          ...state.params,
          page: 1,
          providers: action.payload,
        },
      };
    }
    case CASINO_GAMELIST_CHANGE_ACTIVE_PAGES: {
      return {
        ...state,
        params: {
          ...state.params,
          activePages: action.payload,
        },
      };
    }
    case CASINO_GAMELIST_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CASINO_GAMELIST_CHANGE_PAGE: {
      const {
        items = null,
        type,
        meta: {
          allCount = 0,
          allMobile = 0,
          categories = {},
          totalCount = 0,
          currentProviders = [],
          favouriteCount = 0,
          recentCount = 0,
          currentPage = 1,
        } = {},
      } = action.payload;
      return {
        ...state,
        loading: false,
        error: null,
        data:
          currentPage === 1 || !state.data || type === casinoPaginationChangeType.set
            ? items
            : type === casinoPaginationChangeType.add
            ? [...state.data, ...items]
            : state.data,
        totalCount,
        favouriteCount,
        recentCount,
        categories,
        providers: currentProviders,
        allCount,
        allCountMobile: allMobile,
      };
    }
    case CASINO_GAMELIST_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    case CASINO_GAMELIST_IS_RELOAD_REQUIRED:
      return {
        ...state,
        isReloadRequired: action.payload,
      };
    case CASINO_GAMELIST_PROVIDER_SET:
      return {
        ...state,
        selectedProvider: action.payload,
      }
    default:
      return state;
  }
};
