import Loadable from "react-loadable";

const LandingBetonline = Loadable({
  loader: () =>
    import(/* webpackChunkName: "landing-betonline"*/ "./LandingBetonline.tsx"),
  loading: () => null,
  modules: ["landing-betonline"],
});

export default LandingBetonline;
