import React, { FC, useEffect, useState } from "react";

import { isExternalLink } from "utils/domainUtils.ts";

import { ReactComponent as BestBtcCasino } from "img/marks/bestBtcCasino.svg";
import { ReactComponent as Cgf } from "img/marks/cgf.svg";
import { ReactComponent as Disclaimer } from "img/marks/disclaimer.svg";
import { ReactComponent as License } from "img/marks/license.svg";

import { Mark } from "./types";

const marksList: Mark[] = [
  { icon: <Cgf />, alt: "Verified operator", href: "https://cryptogambling.org/" },
  { icon: <BestBtcCasino />, alt: "Best bitcoin casino award" },
  { icon: <License />, alt: "License" },
  {
    icon: <Disclaimer />,
    alt: "Disclaimer",
    href: "https://www.responsiblegambling.org/",
  },
];

export const Marks: FC = () => {
  const [marks, setMarks] = useState<Array<Mark> | []>([]);

  useEffect(() => {
    if (!marks.length) {
      const licenseIdx = marksList.findIndex((mark) => mark.alt === "License");
      if (licenseIdx !== -1 && !!window) {
        marksList[licenseIdx].href = `${window?.location?.origin}/licensecertificate.pdf`;
      }
      setMarks(marksList);
    }
  }, []);

  return (
    <div className="row mt-4">
      <div className="col-12">
        <div
          className="d-flex align-items-center justify-content-center flex-wrap"
          style={{ gap: "24px" }}
        >
          {marks.map(({ icon, alt, href }) =>
            href ? (
              <a
                key={alt}
                href={href}
                target={!href ? "_self" : "_blank"}
                rel={isExternalLink(href) ? "nofollow noreferrer" : "noreferrer"}
              >
                {icon}
              </a>
            ) : (
              <React.Fragment key={alt}>{icon}</React.Fragment>
            )
          )}
        </div>
      </div>
    </div>
  );
};
