import axios from "axios";
import q from "query-string";

import { CASINO_CLOSED } from "constants/general";

import { localStorageUtils } from "utils/browserUtils";
import { getApiHostname, getHostname } from "utils/domainUtils.ts";

import config from "../config.json";
import { getAuthToken } from "./api";

export const apiType = {
  api: "api",
  serverApi: "serverApi",
  casino: "casino",
};

const apiConfig = {
  timeout: config.apiTimeout,
  headers: { "Content-Type": "application/json" },
};

const setClientLanguage = (apiObj) => {
  try {
    if (typeof window !== "undefined") {
      const language = localStorage.getItem("i18nextLng");
      if (language) apiObj.defaults.headers["client-language"] = language;
    }
  } catch (e) {
    console.log(e);
  }
};

const setDefaultHeaders = (apiObj) => {
  if (typeof window !== "undefined") {
    const urlParams = q.parse(window.location.search);
    const keys = ["hitid", "stag", "subid"];
    const commonKeys = ["uid", "sid"];

    const resolve = (key) => {
      return localStorageUtils?.getItem(key) || urlParams[key];
    };
    keys.forEach((key) => {
      const value = resolve(key);
      if (value) apiObj.defaults.headers[key] = value;
    });
    commonKeys.forEach((key) => {
      const value = resolve(key);
      if (value) apiObj.defaults.headers.common[key] = value;
    });
  }
};

const setInterceptors = (apiObj) => {
  if (apiObj)
  apiObj.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 429) {
        window.location.hash = "#error?error=banned_user"
      }
      return {
        data: {
          ...(error.response?.data ?? {}),
          status: "failed",
          errorCode: error.response?.status,
          errorMessage: error.response?.data?.message,
          responseDataStatus: error.response?.data?.status,
        }
      };
    }
  );
};

const API_VERSION = "v1";

const getServerApiUrl = () => {
  return `//${getHostname()}/${API_VERSION}`;
};

export const getBaseUrl = () => {
  const hostname = getHostname();
  const apiHostname = getApiHostname(hostname);
  return hostname ? `//${apiHostname}/${API_VERSION}` : "";
};

export const getApi = (requestedApiType) => {
  let apiObj;
  const baseURL = getBaseUrl();
  switch (requestedApiType) {
    case apiType.api:
      apiObj = axios.create({
        baseURL,
        ...apiConfig,
      });
      setInterceptors(apiObj);
      setClientLanguage(apiObj);
      setDefaultHeaders(apiObj);
      break;
    case apiType.serverApi:
      apiObj = axios.create({
        baseURL: getServerApiUrl(),
        ...apiConfig,
      });
      setInterceptors(apiObj);
      setClientLanguage(apiObj);
      setDefaultHeaders(apiObj);
      break;
    case apiType.casino:
      apiObj = axios.create({
        baseURL: `${baseURL}/casino`,
        timeout: apiConfig.timeout,
      });

      apiObj.interceptors.request.use(function (c) {
        const token = getAuthToken();
        if (token && !apiObj.defaults.headers["Authorization"])
          apiObj.defaults.headers["Authorization"] = "Bearer " + token;
        if (CASINO_CLOSED) throw new axios.Cancel("Operation canceled.");
        return c;
      });
      break;
  }

  if (apiObj) {
    return apiObj;
  }

  throw new Error(`Api type ${requestedApiType} is not supported`);
};
