import { getAccountCurrency } from "selectors/loginSelectors";

import { currencies, currenciesOrderSocial } from "constants/currencies.ts";

import { getSavedCurrency } from "utils/browserUtils";

export const getOddsFormat = (state) => state.settings.oddsFormat;
export const getViewCurrency = (state) => {
  const fiatView = state?.ui?.viewFiat;
  const fiatCurrency = getFiatCurrency(state);
  const cryptoCurrency = getAccountCurrency(state);
  return !!+fiatView && fiatCurrency ? fiatCurrency : cryptoCurrency;
};
export const getEventGroups = (state) => state.ui.eventGroups;
export const getViewFiat = (state) => state.ui.viewFiat;
export const getSettingsFiatCurrency = (state) => state?.settings?.fiatCurrency;
export const getFiatCurrency = (state) => {
  const viewFiat = getViewFiat(state);
  if (!viewFiat) return "USD";
  return state.settings.fiatCurrency;
};
export const isSideMenuOpened = (state) => state.ui.sideMenu.isOpened;

export const getInitialCurrency = () => {
  let initialCurrency = getSavedCurrency();

  if (!initialCurrency || !currencies[initialCurrency])
    initialCurrency = currenciesOrderSocial[0];

  return initialCurrency;
};

export const getHideZero = (state) => state.settings.hideZero;
export const getIsFiatCurrency = (state) => !!getViewFiat(state);
export const getShowBonus = (state) => state.settings.showBonus;
export const getIsHideZero = (state) => !!state.settings.hideZero;
export const getIsShowBonus = (state) => !!state.settings.showBonus;
