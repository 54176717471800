import { supportedProviders } from "@sbetdev2/dbmodel/enums";
import { placeBet } from "api/eosApi";
import { walletWithdraw } from "api/walletApi";

import { betStatus } from "constants/bets";
import { currencies } from "constants/currencies.ts";

import { buildBetMemo } from "utils/contractUtils";

import {
  formatAssetQty,
  getBalance,
  getRows,
  getTokenAmount,
  getTokenName,
  init,
  login,
  logout,
  makeTransaction,
  transact,
} from "./scatter_16";

const contractName = "sportbet1bet";
const bankName = "sportbetdivs";
const tokenName = "sportbetsbet";
const walletContractName = "sportbetdepo";
const casinoContract = "decentcasino";

const betTransfer = async (
  accountName,
  bets,
  amount,
  referral,
  currency = "EOS",
  options
) => {
  const { code, precision } = currencies[currency];
  const transaction = await makeTransaction((account) => {
    if (referral === account.name) referral = "";
    const memo = buildBetMemo(bets, referral, options);
    return {
      account: code,
      name: "transfer",
      data: {
        from: account.name,
        to: contractName,
        quantity: formatAssetQty(amount, currency, precision),
        memo,
      },
    };
  }, false);
  return await placeBet(transaction.transaction);
};

const betsTransfer = async (accountName, bets, referral, currency = "EOS") => {
  const { code, precision } = currencies[currency];

  const result = await makeTransaction((account) => {
    const actions = [];
    for (const item of bets) {
      const memo = buildBetMemo(
        item.bets,
        referral === account.name ? "" : referral,
        item.options
      );

      actions.push({
        account: code,
        name: "transfer",
        data: {
          from: account.name,
          to: contractName,
          quantity: formatAssetQty(item.amount, currency, precision),
          memo,
        },
      });
    }
    return actions;
  }, false);

  // const { signatures, transaction } = result;

  return await placeBet({ ...result.transaction });
};

const casinoDeposit = async (amount, currency, memo) => {
  const { code, precision } = currencies[currency];

  const transaction = await makeTransaction((account) => {
    return {
      account: code,
      name: "transfer",
      data: {
        from: account.name,
        to: casinoContract,
        quantity: formatAssetQty(amount, currency, precision),
        memo: memo,
      },
    };
  }, false);

  return transaction.transaction;
};

const authTransaction = async (nonce) => {
  return await transact(
    {
      account: contractName,
      name: "auth",
      data: { nonce },
    },
    {
      options: { broadcast: false },
    }
  );
};

const betComplete = async (bet, statuses, reason) => {
  const { legs, _id } = bet;

  if (legs.length > 1) {
    await makeTransaction({
      account: contractName,
      name: "pcomplete",
      data: {
        bet_id: _id,
        statuses: statuses.map((x) =>
          !x || x === betStatus.pending ? betStatus.skipped : x
        ),
        reason,
      },
    });
  } else {
    await makeTransaction({
      account: contractName,
      name: "complete",
      data: {
        bet_id: _id,
        status: statuses[0],
        reason,
      },
    });
  }
};

const eosToFloat = (x) => x * 0.0001;

const getBankStats = async () => {
  const rows = await getRows({
    code: bankName,
    scope: bankName,
    table: "stats",
    limit: 1,
  });
  const stats = rows && rows.length ? rows[0] : { staked: 0, unstaking: 0 };
  //console.log(rows);
  return {
    staked: eosToFloat(stats.staked),
    unstaking: eosToFloat(stats.unstaking),
    //unclaimed: eosToFloat(stats.unclaimed)
  };
};

const getBankTokenStats = async () => {
  const rows = await getRows({
    code: bankName,
    scope: bankName,
    table: "tokenstats",
  });
  const stats = rows.reduce((p, x) => {
    p[getTokenName(x.unclaimed)] = getTokenAmount(x.unclaimed);
    return p;
  }, {});

  return stats;
};

const getBankStake = async (account) => {
  const rows = await getRows({
    code: bankName,
    scope: bankName,
    table: "stakestats",
    lower_bound: account,
    limit: 1,
  });
  const stake =
    rows && rows.length && rows[0].account === account
      ? rows[0]
      : { staked: 0, unstaking: 0 };
  return {
    staked: eosToFloat(stake.staked),
    unstaking: eosToFloat(stake.unstaking),
  };
};

const getBankUnclaimed = async (account) => {
  const rows = await getRows({
    code: bankName,
    scope: account,
    table: "accountdivs",
  });
  if (!rows || !rows.length) return null;
  const dividends = rows.reduce((p, x) => {
    p[getTokenName(x.amount)] = getTokenAmount(x.amount);
    return p;
  }, {});
  return dividends;
};

const getBankLastPayouts = async () => {
  const rows = await getRows({
    code: bankName,
    scope: bankName,
    table: "payrolls",
    reverse: true,
  });
  return rows.reduce((p, x) => {
    const tokenName = getTokenName(x.dividends);
    if (!p[tokenName]) {
      p[tokenName] = {
        stakes: getTokenAmount(x.stakes),
        dividends: getTokenAmount(x.dividends),
      };
    }
    return p;
  }, {});
};

const getBankUserData = async (account) => {
  if (!account) return null;
  const [balance, stake, dividends] = await Promise.all([
    getBalance(tokenName, account, "SBET"),
    getBankStake(account),
    getBankUnclaimed(account),
  ]);
  return {
    balance: balance + stake.staked,
    free: balance, // - stake.staked,
    unstakable: stake.staked - stake.unstaking,
    ...stake,
    dividends,
  };
};

const getTokenDividends = (payout) => {
  if (!payout) return undefined;
  const { dividends = 0, stakes = 0 } = payout;
  return {
    dividends,
    stakes,
    per10000: dividends && stakes ? (dividends * 10000) / stakes : 0,
  };
};

const getDividends = async (account) => {
  const currencyList = Object.values(currencies);

  const tokenBalanceTasks = Promise.all(
    currencyList.map((x) => getBalance(x.code, bankName, x.name))
  );

  const [balances, stats, unclaimed, payout, user] = await Promise.all([
    // EOS balance
    tokenBalanceTasks, // returns one number
    getBankStats(), // return {stake, unstaking}
    getBankTokenStats(), // return {EOS: {dividends: Number, stakes: Number}}
    getBankLastPayouts(), // returns {EOS: Number}
    getBankUserData(account),
  ]);

  const stakes = stats.staked - stats.unstaking;
  const tokenStats = currencyList.reduce((p, { name }, i) => {
    const balance = balances[i];
    p[name] = {
      payout: getTokenDividends(payout[name]),
      current: getTokenDividends({
        stakes,
        dividends: (balance || 0) - (unclaimed[name] || 0),
      }),
    };
    return p;
  }, {});

  return {
    ...tokenStats,
    stakes,
    user,
  };
};

const claimDividends = async () => {
  await makeTransaction((account) => ({
    account: bankName,
    name: "claim",
    data: {
      from: account.name,
    },
  }));
};

const stakeTokens = async (amount) => {
  await makeTransaction((account) => ({
    account: bankName,
    name: "stake",
    data: {
      from: account.name,
      quantity: formatAssetQty(amount, "SBET"),
    },
  }));
};

const unstakeTokens = async (amount) => {
  await makeTransaction((account) => ({
    account: bankName,
    name: "unstake",
    data: {
      from: account.name,
      quantity: formatAssetQty(amount, "SBET"),
    },
  }));
};

const getAccountBalance = async (currencyName = currencies.EOS.name) => {
  try {
    const account = await init();
    if (!account) return undefined;

    const currencyMetadata = currencies[currencyName];
    return await getBalance(currencyMetadata.code, account.name, currencyMetadata.name);
  } catch (e) {
    return undefined;
  }
};

const sendWithdrawal = async (amount, externalAddress) => {
  const currency = currencies.BTC;

  return await makeTransaction((account) => ({
    account: currency.code,
    name: "transfer",
    data: {
      from: account.name,
      to: walletContractName,
      quantity: formatAssetQty(amount, currency.name, currency.precision),
      memo: externalAddress,
    },
  }));
};

const sendTokenWithdrawal = async ({
  to,
  contract,
  quantity,
  code,
  tfaCode,
  providerService,
}) => {
  const contractName = currencies[code]?.code ?? contract;

  const signedTrx = await makeTransaction(
    (account) => ({
      account: contractName,
      name: "transfer",
      data: {
        from: account.name,
        to: "sportbetcurr",
        quantity,
        memo: to,
      },
    }),
    false,
    { tfaCode }
  );

  return await walletWithdraw(
    {
      to,
      code,
      transaction: signedTrx.transaction,
      quantity,
      anchor: false,
    },
    { tfaCode }
  );
};

const sendEosWithdrawal = async ({
  to,
  memo,
  code,
  quantity,
  tfaCode,
  providerService,
}) => {
  return await makeTransaction(
    (account) => ({
      account: code,
      name: "transfer",
      data: {
        from: account.name,
        to: to,
        quantity,
        memo,
      },
    }),
    true,
    { tfaCode }
  );
};

const withdraw = {
  [supportedProviders.ptokens]: sendEosWithdrawal,
  [supportedProviders.bitgo]: sendTokenWithdrawal,
};

const approveWithdrawal = async ({ blockchainId, externalTxId }) => {
  return await makeTransaction((account) => ({
    account: walletContractName,
    name: "withdraw",
    data: {
      from: account.name,
      id: blockchainId,
      tx_id: externalTxId,
    },
  }));
};

const rejectWithdrawal = async ({ blockchainId, rejectionMessage }) => {
  return await makeTransaction((account) => ({
    account: walletContractName,
    name: "wcancel",
    data: {
      from: account.name,
      id: blockchainId,
      reason: rejectionMessage,
    },
  }));
};

export {
  init,
  login,
  authTransaction,
  logout,
  betTransfer,
  betsTransfer,
  casinoDeposit,
  betComplete,
  getDividends,
  claimDividends,
  stakeTokens,
  unstakeTokens,
  getAccountBalance,
  sendWithdrawal,
  approveWithdrawal,
  rejectWithdrawal,
  withdraw,
};
