import { normalizeEvent, normalizeLeague } from "utils/projectUtils";

import { getAuthToken } from "./api";
import { apiType, getApi } from "./apiFactory";

export const fetchEvents = async ({ sport, since, ids, type, isVip }) => {
  try{
    const params = {};
    if (sport) params.sport = sport;
    if (type) params.type = type;
    if (since) params.since = since;
    if (ids) params.ids = ids.join("_");
  
    const { data: result } = await getApi(apiType.serverApi).get("/events", {
      params,
    });
    if(result?.errorCode) {
      throw new Error(result?.errorMessage)
    }
    result.leagues = ([...result.leagues] || []).map(normalizeLeague);
  
    let getEventSport;
    if (result.sport) {
      getEventSport = () => result.sport;
    } else {
      const leagueSports = result.leagues.reduce((p, x) => {
        p[x._id] = x.sport;
        return p;
      }, {});
      getEventSport = (x) => leagueSports[x.l];
    }
  
    result.events = (result.events || []).map((x) =>
      normalizeEvent(x, getEventSport(x), isVip)
    );
  
    return result;
  } catch(err){
    console.error(err)
    throw new Error(err)
  }
};

export const fetchEventById = async (eventId, project) => {
  const response = await getApi(apiType.serverApi).post(`/events/event/${eventId}`, { project });
  return response.data;
};

export const fetchBreadcrumbs = async (eventId) => {
  try {
    const token = getAuthToken();
    const config = {};

    if (token) {
      config.headers = {
        Authorization: "Bearer " + token,
      };
    }
    const response = await getApi(apiType.serverApi).get(`/events/breadcrumbs/${eventId}`, config);
    return response.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export const fetchHomepageEvents = async (sportId, vip) => {
    const response = await getApi(apiType.serverApi).get(`/events/hp?sport=${sportId}${vip ? "&vip=1" : ""}`);

    return response.data;
};
